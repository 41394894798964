
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.swal-container{height: 380px;overflow-y: scroll;}
.selected-item-container .selected-item{max-width: 100%!important;}
.multiselect-dropdown{background: #ffffff!important;}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item{
  border: 1px solid #eeeeee!important;
  background: #eeeeee!important;
  color: #000000!important;
  border-radius: 8px!important;
}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a{
  color: #000000!important;
}
.input-group-append{
  position: absolute;
  top: 35px;
  right: 15px;
}

.registration-container .area-code {
  margin-left: 15px;
  font-weight: 500;
  font-size:16px;
  position: absolute;
}

.phone-field input[type="text"]{
  padding-left: 46px;
  font-size:16px;
}

.blurred {
  filter: blur(5px);
}

.truncate{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 420px) {
  .otp-input {
    padding: 0!important;
  }
}
